.root + .root {
  margin-top: 24px;
}
.content {
  border-radius: 6px;
  background-color: #415348;
}
.header {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  display: flex;
}
.title {
  line-height: 32px;
  font-size: 18px;
}
.button {
  margin-left: auto;
  margin-right: -12px;
}