.root {
  padding: 0 20%;
}
.heading {
  padding: 64px 0;
  font-size: 48px;
  text-transform: uppercase;
  text-align: center;
  color: #7de4d7;
}
.tabs {
  display: flex;
}
.tab {
  flex: 1 0 0;
  text-align: center;
}
.tab > span {
  border-bottom: 1px solid transparent;
  padding: 0 8px 8px;
  font-size: 16px;
  cursor: pointer;
}
.tab.current > span {
  border-bottom-color: #7de4d7;
}
.controls {
  padding: 40px 0 64px;
}
.control {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #7de4d7;
  background-color: transparent;
  line-height: 1;
  font-size: 16px;
  color: #fff;
}
.control:focus {
  outline: none;
}
.control::placeholder {
  color: #99c7c6;
}
.control + .control {
  margin-top: 16px;
}
.commit {
  border: 0;
  width: 100%;
  padding: 0;
  font-size: 18px;
  border-radius: 4px;
  display: block;
  background-color: #7de4d7;
  line-height: 48px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.error {
  margin-top: 12px;
  text-align: center;
  color: #da4b4b;
}
.control + .error {
  text-align: left;
  margin-bottom: 12px;
}