.root {
  padding: 8px 12px;
}
.columns {
  font-weight: bold;
  display: flex;
}
.columns + .rows {
  margin-top: 8px;
}
.rows > .row + .row {
  margin-top: 8px;
}
.row {
  width: 100%;
  display: flex;
}
.row > div {
  flex: 1 0 0;
}
.placeholder {
  padding: 24px 0;
  text-align: center;
}