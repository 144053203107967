.root {
  position: fixed;
  z-index: 1300;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  padding: 16px;
  border-radius: 8px;
  background-color: #5b7265;
}