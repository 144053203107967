.root {
  width: 320px;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
.content {
  margin-top: 12px;
  margin-bottom: 24px;
}
.group {
  display: flex;
  align-items: center;
}
.group + .group {
  margin-top: 16px;
}
.label {
  width: 40%;
}
.input {
  width: 60%;
  padding: 4px 8px;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: transparent;
  color: #fff;
}
.hasError {
  border-color: #cb2222;
}
.input:read-only {
  opacity: 0.5;
}
.input:focus {
  outline: none;
}
.actions {
  display: flex;
}
.actions > * {
  flex: 1 0;
}
.actions > * + * {
  margin-left: 24px;
}
.error {
  margin-bottom: 12px;
  color: #772222;
}