.root {
  padding: 12px 12px;
  margin-bottom: 24px;
  border-bottom: 1px solid #89b3b0;
  display: flex;
  align-items: center;
}
.logOut {
  cursor: pointer;
}
.action {
  margin-left: auto;
  text-align: center;
}
.action > span {
  display: block;
  font-size: 16px;
  font-weight: bold;
}
.label {
  flex: 1;
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #7de4d7;
}