.root {
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.primary {
  background-color: #7de4d7;
}
.secondary {
  background-color: #263e3f;
}
.lg {
  padding: 0;
  line-height: 40px;
  font-size: 16px;
}
.md {
  padding: 8px 12px;
  font-size: 16px;
}
.disabled {
  opacity: 0.5;
  cursor: default;
}